import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b716c32b4ffe43793e332d87a45b868f@o4506309919113216.ingest.us.sentry.io/4508206091075584",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});